import React, { Suspense, lazy } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { render } from "react-dom";
import LinearProgress from '@material-ui/core/LinearProgress';
// import RootContainers from "containers/RootContainers";
// import { NotFound, BackendError } from "pages";


// import { Signin, Auth, EnquiryForm } from "containers";

const RootContainers = lazy(() => import('containers/RootContainers'));
const Signin = lazy(() => import('containers/Auth/Signin'));
const Auth = lazy(() => import('containers/Auth/Auth'));
const EnquiryForm = lazy(() => import('containers/Admission/Enquiry/EnquiryForm/EnquiryForm'));
const Register = lazy(() => import('containers/Admission/Enquiry/EnquiryForm/Register'));
const StaffEnquiryForm = lazy(() => import('containers/staff/Enquiry/StaffEnquiryForm'));

const NotFound = lazy(() => import('pages/Errors/NotFound'));
const BackendError = lazy(() => import('pages/Errors/BackendError'));

const user = localStorage.getItem("myData");

let route = (
    <Switch>
        <Route exact path="/404" component={NotFound} />
        <Route exact path="/500" component={BackendError} />
        <Route exact path="/enquiry-form" component={EnquiryForm} />
        <Route exact path="/register/:ken_id" component={Register} />
        <Route exact path="/staff-enquiry" component={StaffEnquiryForm} />
        <Route exact path="/signin" component={Signin} />
        <Route path="/" component={Auth} />
        <Redirect to="/" />
    </Switch>
);

if (user) {
    route = (
        <Switch>
            <Route exact path="/enquiry-form" component={EnquiryForm} />
            <Route exact path="/register/:ken_id" component={Register} />
            <Route path="/" component={RootContainers} />
            <Route path="/" component={Auth} />
            <Redirect to="/" />
        </Switch>
    );
}

render(
    <BrowserRouter><Suspense fallback={<div><LinearProgress color="secondary" value="50" /></div>}>{route}</Suspense></BrowserRouter>,

    document.getElementById("root")
);
